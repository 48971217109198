@mixin mediaGen($class) {
  @for $i from 1 through 12 {
    &.#{$class}-#{$i} {
      grid-column: span #{$i};
    }
  }

  @container (max-width: 1350px) {
    @for $i from 1 through 6 {
      &.#{$class}-#{$i} {
        grid-column: span #{$i * 2};
      }
    }

    @for $i from 7 through 12 {
      &.#{$class}-#{$i} {
        grid-column: span 12;
      }
    }
  }

  @container (max-width: 720px) {
    @for $i from 1 through 4 {
      &.#{$class}-#{$i} {
        grid-column: span #{$i * 3};
      }
    }

    @for $i from 5 through 12 {
      &.#{$class}-#{$i} {
        grid-column: span 12;
      }
    }
  }

  @container (max-width: 480px) {
    @for $i from 1 through 2 {
      &.#{$class}-#{$i} {
        grid-column: span #{$i * 6};
      }
    }

    @for $i from 3 through 12 {
      &.#{$class}-#{$i} {
        grid-column: span 12;
      }
    }
  }

  @container (max-width: 250px) {
    @for $i from 1 through 12 {
      &.#{$class}-#{$i} {
        grid-column: span 12;
      }
    }
  }
}

@mixin noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}