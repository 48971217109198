.a-service-file-item {
  a {
    display: grid;
    grid-template-columns: 31px 1fr;
    grid-gap: 24px;
    padding: 0 24px;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    transition: .25s background-color;
    color: #000!important;

    > span {
      font-size: 14px;
      font-family: Manrope, sans-serif;
      font-weight: 400;
      line-height: 100%;
    }

    &:hover {
      background-color: rgba(239, 243, 251, 0.5);
      color: #000!important;
    }
  }
}

.a-service-file-list {
  border: 1px solid rgba(219, 224, 236, 1);
  color: #000;
  text-decoration: none;
  font-size: 14px;
  font-family: Manrope, sans-serif;
  font-weight: 400;
  border-radius: 18px;
  overflow: hidden;

  a {
    color: #000;
    text-decoration: none;
  }

  .header {
    font-weight: 600;
    color: rgba(34, 38, 45, 1);
    background-color: rgba(239, 243, 251, 1);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding: 0 24px;
  }

  > div {
    border-bottom: 1px solid rgba(219, 224, 236, 1);

    &:last-of-type {
      border-bottom: none;
    }
  }
}