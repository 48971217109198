.a-service-template-list {
  display: grid;
  grid-template-columns: 267px 1fr;
  grid-template-rows: auto;
  align-items: flex-start;
  grid-gap: 24px;

  &-filter {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-columns: auto;
    grid-gap: 16px;

    padding: 20px 16px;
    border-radius: 18px;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.07);
    color: rgba(0, 0, 0, 1);

    &-title {
      font-family: Manrope, sans-serif;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.02em;
    }

    &-resetAll {
      display: inline-block;
      font-family: Manrope, sans-serif;
      font-size: 14px;
      font-weight: 600;
      color: rgba(52, 96, 180, 1);
      transform: scale(1);
      transition: 0.25s transform, 0.25s color;
      backface-visibility: hidden;
      cursor: pointer;

      @include noselect();

      &:hover {
        color: rgb(35, 63, 122);
        transform: scale(1.05);
      }
    }

    &-item {
      display: grid;
      grid-template-columns: 1fr min-content;
      grid-auto-rows: auto;
      align-items: center;

      .title {
        font-family: Manrope, sans-serif;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        min-height: 28px;
      }

      .collapse {
        margin-right: -8px;

        svg {
          font-size: 18px !important;
          color: rgba(112, 112, 112, 1) !important;
        }
      }

      .filter-field {
        grid-column: span 2;

        &-collapse {
          > div:first-of-type {
            padding-bottom: 2px;
          }
        }

        &-reset {
          display: inline-block;
          font-family: Manrope, sans-serif;
          font-size: 12px;
          font-weight: 400;
          color: rgba(52, 96, 180, 1);
          transform: scale(1);
          backface-visibility: hidden;
          transition: 0.25s transform, 0.25s color;
          cursor: pointer;

          @include noselect();

          &:hover {
            color: rgb(35, 63, 122);
            transform: scale(1.1);
          }
        }
      }
    }

    &-list-field {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      padding-top: 4px;
      grid-gap: 4px;

      &-options {
        display: grid;
        grid-template-rows: 1fr;
        grid-gap: 6px;

        .MuiFormControlLabel-root {
          margin-left: -2px !important;
        }

        &.collapse {
          padding-bottom: 4px;
        }
      }

      &-option-checkbox {
        .MuiCheckbox-root, .MuiIconButton-root {
          padding: 0 !important;
          margin-right: 8px !important;
        }

        .MuiRadio-root svg {
          color: #0EAB70 !important;
        }

        .checkbox-label {
          font-family: Manrope, sans-serif !important;
          font-size: 12px !important;
          font-weight: 500 !important;
          line-height: 100% !important;
          padding-top: 1px;

          @include noselect();

          .label-container {
            display: grid;
            grid-auto-rows: auto;
            grid-template-columns: 1fr;
            grid-gap: 2px;

            &.with-description {
              padding: 2px 0;
            }

            > div:nth-of-type(2) {
              font-size: 10px;
              line-height: 100%;
              color: rgba(112, 112, 112, 1);
            }
          }
        }
      }

      &-expand {
        display: inline-block;
        font-family: Manrope, sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: rgba(112, 112, 112, 1);
        cursor: pointer;
        transition: 0.25s transform, 0.25s color;
        transform: scale(1);
        backface-visibility: hidden;

        @include noselect();

        &:hover {
          color: rgb(67, 66, 66);
          transform: scale(1.1);
        }
      }

      &-take-all {
        display: inline-block;
        font-family: Manrope, sans-serif;
        font-size: 12px;
        font-weight: 600;
        color: rgba(112, 112, 112, 1);
        cursor: pointer;
        transition: 0.25s transform, 0.25s color;
        transform: scale(1);
        backface-visibility: hidden;

        @include noselect();

        &:hover {
          color: rgb(67, 66, 66);
          transform: scale(1.1);
        }
      }
    }

    &-slider-field {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;

      > div:first-of-type {
        grid-column: span 2;
        padding: 0 6px;

        .MuiSlider-root {
          padding: 6px 0 !important;

          &.MuiSlider-marked {
            padding: 10px 0 0 !important;
          }
        }

        .MuiSlider-valueLabelLabel {
          font-size: 14px;
        }

        .MuiSlider-markLabel {
          top: 18px !important;
        }
      }

      .caption {
        font-family: Manrope, sans-serif;
        font-size: 12px;
        font-weight: 500;

        &.right {
          text-align: right;
        }
      }
    }
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: 24px;
  }

  &-progress {
    height: 4px;
    margin-bottom: 12px;
  }

  &-content--sort {
    padding-bottom: 12px;
  }

  &-content--sort-tab {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    grid-template-rows: min-content;
    grid-gap: 8px;
    align-items: center;

    > div {
      min-height: 18px;
    }

    .arrow {
      line-height: 0 !important;
      width: 18px;

      svg {
        font-size: 18px !important;
        line-height: 0;
        color: #383838 !important;
        transition: .25s transform, .25s color !important;
      }

      &.revert {
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    &:hover {
      .arrow {
        svg {
          color: #0EAB70 !important;
          transform: rotate(-180deg);
        }

        &.revert svg {
          transform: rotate(0deg);
        }
      }
    }
  }

  &-content-tab {
    &--selected {
      color: #383838 !important;
    }
  }
}