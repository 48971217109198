.route-notes {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  padding: 12px 16px;
  border: 2px solid #ffa630;
  box-shadow: 0 0 12px rgba(255, 166, 48, .2);
  border-radius: 12px;

  .route-is-not-full {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 4px 12px;

    > svg {
      grid-row: span 2;
    }

    > div {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #000;
    }

    > div:nth-of-type(2) {
      font-size: 12px;
      font-weight: 300;
      color: #707070;
    }
  }
}