#a-service-iframe {
  display: none;
}

.a-service-widget-form {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 96px;
  grid-gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;

  > div.selectors {
    grid-column: 1;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;
    justify-content: flex-start;
    align-items: flex-end;
    grid-gap: 20px;
    container-type: inline-size;

    .a-service-widget-form-directory-selector-block {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-auto-rows: min-content;
      align-items: flex-end;

      @include mediaGen("size");

      .a-service-widget-form-directory-selector-container {
        @for $i from 1 through 12 {
          &.size-#{$i} {
            grid-column: span #{$i};
          }
        }

        .a-service-widget-form-directory-selector-name {
          color: #707070;
          font-family: Manrope, sans-serif;
          font-size: 11px;
          font-weight: 600;
          line-height: 110%;

          padding-bottom: 4px;

          width: 100%;

          &.direction-left {
            text-align: left;
          }

          &.direction-right {
            text-align: right;
          }

          &.direction-center {
            text-align: center;
          }
        }
      }
    }
  }

  > div.buttons {
    grid-column: 2;
    display: flex;
    justify-content: flex-end;
  }

  > div.fields {
    container-type: inline-size;

    &.single-row {
      grid-row: 1/3;
    }
  }

  > div.share {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    grid-auto-flow: column;

    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }

  .a-service-widget-form-loading {
    height: 100%;
    display: grid;
    grid-template-columns: min-content;
    grid-template-rows: auto;
    align-items: center;
  }

  .a-service-widget-form-directory-selector {
    .MuiSelect-select, .MuiMenuItem-root {
      font-size: 14px !important;
      font-family: Manrope, sans-serif !important;
      font-weight: 600 !important;
      color: black !important;
    }
  }
}

.a-service-widget-form-directory-selector-option {
  font-size: 14px !important;
  font-family: Manrope, sans-serif !important;
  font-weight: 600 !important;
  color: black !important;
  margin-bottom: 4px !important;

  &:last-of-type {
    margin-bottom: 0 !important;
  }
}