@import url('https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

input {
  background: #fff;
  border: none;
  border-radius: unset;
  color: unset;
  font-size: unset;
  padding: unset;
}

$spacing: 8px;

@import 'colors';
@import 'fonts';
@import 'routes';
@import "addition-services";
@import "basket";
