.a-service-template-additionList {
  @include defaultTableBlockStyle();
  grid-template-columns: 1fr 2fr auto;
  container-type: inline-size;

  @container (max-width: 1000px) {
    grid-template-columns: 1fr 1fr auto;
  }

  @container (max-width: 800px) {
    grid-template-columns: 2fr 250px auto;
  }

  &-title {
    @include defaultTableTitleStyle();
  }

  &-price {
    @include defaultTablePriceStyle();
  }

  &-cost {
    @include defaultHeaderCostCellMixin();
  }

  &-include-button {
    @include defaultIncludeButtonMixin();
  }

  &-list {
    grid-column: span 3;
    container-type: inline-size;
    overflow-x: auto;
  }

  &-scroll {
    display: grid;
    grid-gap: 0;
    min-width: 1100px;

    grid-template-columns: 7fr 3.5fr 2fr 2fr 3fr 1fr 2fr 3fr;

    @container (max-width: 1700px) {
      grid-template-columns: 6fr 3.5fr 2fr 2fr 3fr 1fr 2fr 3fr;
    }

    @container (max-width: 1400px) {
      grid-template-columns: 5fr 4.5fr 3fr 2fr 3fr 1fr 2fr 3fr;
    }

    > div {
      @include defaultTableCellStyle();
    }
  }

  &-fields {
    @include defaultTableFieldsStyle();
  }
}