.a-service-template-costConfiguration {
  &.center {
    display: grid;
    justify-content: center;
    text-align: center;
  }

  &.fields {
    .addition-service-fields-row {
      justify-content: center;
      align-items: center;
      grid-template-columns: auto auto;
      grid-auto-rows: auto;
      grid-auto-flow: column;
      grid-gap: 8px;

      > div {
        grid-column: span 1 !important;
      }
    }
  }
}