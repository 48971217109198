.a-service-data {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 24px;
  padding-top: 24px;

  .a-service-data-loading {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 24px;
  }

  .a-service-data-variants {
    .a-service-data-variants-tab--content {
      display: grid;
      grid-auto-columns: auto;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(3, min-content);
      grid-gap: 12px;
      align-items: center;
      height: 16px;

      > div {
        white-space: nowrap;
        line-height: 100%;
        display: flex;
        align-items: center;

        &:after {
          display: block;
          content: "-";
          padding-left: 12px;
          align-items: center;
        }

        &:last-of-type:after {
          display: none;
        }
      }

      > &-progress {
        line-height: 0;
      }
    }
  }

  .a-service-data-description {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 12px;

    font-size: 14px;
    color: #707070;
  }
}