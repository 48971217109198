.a-service-template-serviceInCargoDeclaration {
  &-name-column {
    grid-auto-columns: 16px 1fr;
    grid-auto-flow: column;
    grid-gap: 8px;
    grid-template-rows: 1fr;
  }

  &-single-price-column {
    grid-column: span 7;
    justify-content: center !important;
  }

  &-quantity {
    justify-content: center !important;
    padding: 8px !important;

    .addition-service-fields-row {
      justify-content: center;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      grid-auto-flow: row;
      grid-gap: 8px;

      > div {
        grid-column: 1 !important;
      }
    }
  }
}