.a-service-add-to-basket-button {
  border: 2px solid rgba(52, 96, 180, 1);
  color: rgba(52, 96, 180, 1);
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  width: 120px;
  text-align: center;
  transition: .25s color, .25s border-color, .25s transform;
  @include noselect();

  &:hover {
    transform: scale(1.1)
  }

  &:active {
    transform: scale(1.2);
  }

  &.remove {
    border-color: #0eab70;
    color: #0eab70;
  }
}