.a-service-template-cargoDeclaration {
  @include defaultTableBlockStyle();
  grid-template-columns: 1fr 2fr auto;

  @container (max-width: 700px) {
    grid-template-columns: 1fr 250px auto;
  }

  &-title {
    @include defaultTableTitleStyle();
  }

  &-price {
    @include defaultTablePriceStyle();
  }

  &-cost {
    @include defaultHeaderCostCellMixin();
  }

  &-include-button {
    @include defaultIncludeButtonMixin();
  }

  &-list {
    grid-column: span 3;
    container-type: inline-size;
    overflow-x: auto;
  }

  &-scroll {
    display: grid;
    grid-template-columns: 5fr 2fr 2fr 2fr 1fr 2fr 3fr 1fr 2fr 3fr;
    grid-gap: 0;
    min-width: 1100px;

    @container (max-width: 1400px) {
      grid-template-columns: 5fr 2fr 3fr 2fr 1fr 2fr 3fr 1fr 2fr 3fr;
    }

    > div {
      @include defaultTableCellStyle();
    }
  }

  &-fields {
    @include defaultTableFieldsStyle();

    .addition-service-fields-row {
      grid-gap: 14px;
    }
  }
}