.a-service-template-verticalTable {
  @include defaultTableBlockStyle();
  grid-template-columns: 2fr 3fr auto;

  @container (max-width: 700px) {
    grid-template-columns: 1fr 250px auto;
  }

  &-title {
    @include defaultTableTitleStyle();
  }

  &-price {
    @include defaultTablePriceStyle();
  }

  &-cost {
    @include defaultHeaderCostCellMixin();
  }

  &-include-button {
    @include defaultIncludeButtonMixin();
  }

  &-price.with-wrapper {
    padding: 20px 32px 20px 0;
  }

  &-include-button.with-wrapper {
    padding: 20px 32px 20px 0;
  }

  .header-with-description {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: stretch;
    row-gap: 2px;

    .table-header-description-icon {
      display: inline-flex !important;
      align-items: center !important;
      width: 16px;
      height: 16px;
      line-height: 16px !important;
      background-color: #DBE0EC !important;
    }
  }

  .with-left-padding {
    padding-left: 20px !important;
  }

  .with-bold-text {
    font-weight: 600;
  }

  &-list {
    grid-column: span 3;
    container-type: inline-size;
    overflow-x: auto;

    &.with-wrapper {
      padding: 0 32px 32px;
    }

    &.with-wrapper &-wrapper {
      border-radius: 18px;
      overflow: hidden;
      overflow-x: auto;
      border: 1px solid #DBE0EC;

      .header {
        border-top: none;
      }
    }
  }

  &-scroll {
    display: grid;
    grid-template-columns: 3fr 2.5fr 2fr 2fr 2fr;
    grid-gap: 0;
    min-width: 1100px;

    @for $i from 1 through 6 {
      &.columns-#{$i} {
        grid-template-columns: 2fr 2.5fr repeat($i, 2fr) 2fr 2fr 2fr;
      }

      &.columns-with-course-#{$i} {
        grid-template-columns: 3fr 4fr 2fr repeat(#{$i - 1}, 3fr) 3fr 3fr 3fr;
      }
    }

    @for $i from 1 through 6 {
      .span-size-#{$i} {
        grid-column: span $i;
      }
    }

    .addition-service-fields-row {
      grid-gap: 14px;
    }

    .addition-service-fields-container {
      .addition-service-fields-row {
        grid-gap: 8px;
      }
    }

    > div {
      @include defaultTableCellStyle();
    }
  }

  .verticalTable-column-fields {
    container-type: inline-size;
    width: 100%;
    grid-template-columns: 1fr;

    .addition-service-fields-list {
      width: 100%;
    }
  }

  &-fields {
    @include defaultTableFieldsStyle();

    .addition-service-fields-row {
      grid-gap: 14px;
    }
  }
}