.a-service-template-customsPayments-base {
  &-scroll {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0;

    > div {
      @include defaultTableCellStyle();
    }
  }
}


.a-service-template-customsPayments {
  &-list {
    container-type: inline-size;
    overflow-x: auto;
  }

  &-scroll {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0;

    > div {
      @include defaultTableCellStyle();
    }
  }
}
