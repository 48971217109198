.route-info-wrap {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.route-info-title {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &.content-visible {
    margin-bottom: 8px;
  }

  &.clickable {
    cursor: pointer;
  }
}

.route-infor-text {
  color: #707070;
  font-size: 12px;

  & p {
    padding: 8px 0 0 0 !important;
    margin: 0 !important;
  }
}
