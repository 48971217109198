.a-service-template-serviceInList {
  border-radius: 18px;
  box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.07);
  background: #fff;
  min-height: 167px;
  position: relative;
  overflow: hidden;

  &-buttons {
    width: 109px;
    position: absolute;
    top: 24px;
    right: 24px;

    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-gap: 8px;

    &-button {
      display: flex;
      border-radius: 8px;
      border: 1px solid rgba(112, 112, 112, 1);
      font-size: 14px;
      color: rgba(112, 112, 112, 1);
      font-weight: 400;
      font-family: Manrope, sans-serif;
      line-height: 19px;
      text-align: center;
      min-height: 29px;
      transition: .25s transform;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include noselect();

      svg {
        font-size: 15px !important;
        margin-right: 4px;
      }

      &:hover {
        transform: scale(1.1);
      }

      &:active {
        transform: scale(1.2);
      }

      &.prime {
        border: 1px solid rgba(52, 96, 180, 1);
        color: rgba(52, 96, 180, 1);
        font-weight: 600;
      }

      &.loading {
        border: 1px solid rgba(112, 112, 112, 1);
        color: rgba(112, 112, 112, 1);
      }

      &.in-order {
        border: 1px solid rgb(14, 152, 97);
        color: rgb(14, 152, 97);

        &:hover {
          transform: scale(1);
        }

        &:active {
          transform: scale(1);
        }
      }

      &.no-border {
        border: none;
      }

      &.disabled {
        &:hover {
          transform: scale(1);
        }

        &:active {
          transform: scale(1);
        }
      }
    }
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr 2fr 160px;
    grid-auto-rows: auto;
    grid-gap: 12px 24px;
    padding: 24px 160px 12px 24px;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    align-items: flex-start;

    .title {
      font-size: 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      > div:first-of-type {
        margin-right: 8px;
      }

      button {
        line-height: 0;
      }
    }

    .price {
      grid-row: span 2;
      text-align: right;

      > div:nth-of-type(1) {
        font-size: 16px;
        font-weight: 600;

        &.header-text-only {
          font-size: 16px;
        }
      }

      > div:nth-of-type(2) {
        font-size: 12px;
        color: rgba(112, 112, 112, 1);
      }
    }

    .field {
      container-type: inline-size;
      grid-row: span 2;

      .addition-service-fields-list .addition-service-fields-row {
        direction: rtl;

        * {
          direction: ltr;
        }
      }
    }

    div:nth-of-type(4) {
      grid-column: span 1;
    }
  }

  .property {
    grid-column: span 2;
    font-weight: 500;
    line-height: 110%;

    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: 2px;

    .description {
      font-size: 12px;
      font-weight: 400;
      color: rgba(112, 112, 112, 1);
    }

    .name {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: baseline;
      justify-content: flex-start;

      .description {
        margin-right: 4px;
      }

      .description:last-of-type {
        margin-right: 0;
      }
    }
  }

  &-description {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    grid-gap: 12px 0;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    align-items: flex-start;
    padding: 0 24px 12px;

    > div.property:last-of-type {
      margin-bottom: 12px;
    }

    .description-container {
      padding-top: 12px;
    }

    > div {
      grid-column: 1;
    }

    .files-container {
      padding-top: 12px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-auto-flow: row;
      grid-gap: 12px 0;
    }
  }

  &-collapse {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    color: rgba(56, 56, 56, 1);
    padding: 4px 0 8px;
    transition: .25s background-color;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    svg {
      font-size: 20px !important;
      color: rgba(56, 56, 56, 1) !important;
      line-height: 0 !important;
    }

    &:hover {
      background: rgba(56, 56, 56, .05);
    }
  }
}