.a-service-basket {
  container-type: inline-size;
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-gap: 12px;
  align-items: flex-start;

  .caption {
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    box-sizing: border-box;
  }

  .properties {
    grid-row: 2;
    font-size: 13px;
    font-weight: 300;
    min-height: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4px 18px;

    @container (max-width: 650px) {
      gap: 4px 16px;
    }

    @container (max-width: 500px) {
      gap: 4px 12px;
    }

    .property {
      font-weight: 500;
      line-height: 110%;

      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      grid-gap: 2px;

      .description {
        font-size: 11px;
        font-weight: 400;
        color: rgba(112, 112, 112, 1);
      }

      .name {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: baseline;
        justify-content: flex-start;

        .description {
          margin-right: 4px;
        }

        .description:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &-fields {
    grid-row: 3;
    grid-column: span 2;

    @mixin listStyle($columns) {
      div.list {
        display: grid;
        grid-template-columns: repeat($columns, auto);
        grid-auto-flow: row;
        grid-gap: 24px 0;
        font-size: 13px;

        > div {
          background: #fff;
          box-shadow: 0 0 0 1px #dbe0ec;
          overflow: hidden;
          display: grid;
          grid-template-rows: min-content 1fr;
          align-items: center;

          div {
            padding: 8px 16px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          div:first-of-type {
            background: #eff3fb;
            font-weight: 600;
          }
        }

        > div:nth-of-type(#{$columns + 1}n) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        > div:nth-of-type(#{$columns + 1}n + 1) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        > div:last-of-type,
        > div:nth-of-type(#{$columns}n) {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }

        > div:first-of-type,
        > div:nth-of-type(#{$columns}n + 1) {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
      }
    }

    @include listStyle(8);

    @container (max-width: 1400px) {
      @include listStyle(7);
    }

    @container (max-width: 1200px) {
      @include listStyle(6);
    }

    @container (max-width: 950px) {
      @include listStyle(5);
    }

    @container (max-width: 800px) {
      @include listStyle(4);
    }

    @container (max-width: 650px) {
      @include listStyle(3);
    }

    @container (max-width: 500px) {
      @include listStyle(2);
    }

    @container (max-width: 350px) {
      @include listStyle(1);
    }
  }
}

.is-mobile .a-service-basket {
  grid-template-columns: 1fr;

  &-fields {
    grid-row: 3;
    grid-column: 1;
  }
}

.a-service-basket-single-service {
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid rgba(219, 224, 236, 1);
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.07);
  color: rgba(0, 0, 0, 1);

  .caption {
    min-height: 73px;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 24px;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    padding: 12px 24px;

    > div {
      display: flex;
      align-items: center;
    }

    > div:nth-of-type(1) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;

      .fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;

        div {
          font-size: 14px;
          font-weight: 500;

          .field-name {
            font-size: 12px;
            font-weight: 300;
          }
        }
      }
    }

    > div:nth-of-type(2) {
      justify-content: flex-end;
      text-align: right;
    }
  }

  .table-data {
    display: grid;
    grid-template-columns: 4fr 2fr 1fr 2fr 2fr 1fr 2fr 2fr;
    font-size: 14px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 8px;
      background: #fff;
      min-height: 60px;

      border-left: 1px solid #dbe0ec;
      border-top: 1px solid #dbe0ec;

      &.no-left-border {
        border-left: none;
      }
    }

    .service-name {
      justify-content: flex-start;
      padding-left: 16px;
    }

    .by-request {
      grid-column: span 7;
    }

    .table-data-header {
      font-weight: 500;
      background: rgba(239, 243, 251, 1);
    }

    .tax {
      grid-column: span 2;
    }
  }
}

.a-service-basket-group {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 24px;
  align-items: flex-start;
  background: rgba(237, 243, 253, 1);
  border-radius: 18px;
  padding: 24px 48px 24px 24px;
  color: rgba(0, 0, 0, 1);

  .group-caption {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;

    > div:first-of-type {
      font-size: 16px;
      font-weight: 600;
    }

    .fields {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4px;

      font-size: 14px;
      font-weight: 500;

      span {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  .group-price {
    display: grid;
    grid-template-columns: max-content;
    grid-gap: 6px;
    font-size: 14px;
    font-weight: 400;

    > div:first-of-type {
      font-size: 16px;
      font-weight: 600;
    }

    span {
      font-weight: 600;
    }
  }
}

.a-service-basket--summary {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 0;
  align-items: stretch;
  border: 1px solid rgba(219, 224, 236, 1);
  box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.07);
  border-radius: 18px;
  overflow: hidden;
  color: rgba(0, 0, 0, 1);

  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
  }

  &-tax {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    @media screen and (max-width: 1023px) {
      order: 2;
    }

    div {
      min-height: 50px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;

      @media screen and (max-width: 1023px) {
        padding: 0 7.5px;
      }

      > div:nth-of-type(1) {
        font-weight: 600;
        font-size: 14px;
      }

      > div:nth-of-type(2) {
        font-weight: 600;
        font-size: 16px;
        text-align: right;
      }
    }
  }

  &-conversions {
    grid-row: span 2;
    padding: 12px 24px;
    background: rgba(239, 243, 251, 1);
    box-shadow: 0 0 0 1px rgba(219, 224, 236, 1);
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;
    grid-gap: 12px 0;
    border-radius: 18px 18px 18px 0;
    font-size: 14px;

    @media screen and (max-width: 1023px) {
      order: 1;
      padding: 12px 15px;
      border-radius: 18px;
    }

    > div:nth-of-type(1) {
      font-weight: 600;
      font-size: 16px;
      white-space: nowrap;
    }

    &-list {
      display: grid;
      grid-template-columns: 1fr;

      > div {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 300;

        > div:nth-of-type(2) {
          font-weight: 500;
          text-align: right;
        }
      }
    }

    > div:nth-of-type(3) {
      display: grid;
      grid-template-columns: min-content 1fr;
      grid-gap: 8px;
      font-size: 12px;
      font-weight: 300;
      align-items: center;

      > div:nth-of-type(1) {
        white-space: wrap;
      }

      > div:nth-of-type(2) {
        font-weight: 500;
        text-align: right;
        font-size: 14px;
      }
    }
  }

  &--total {
    min-height: 60px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 32px;
    background: rgba(52, 96, 180, 1);
    color: #fff;
    font-size: 20px;
    font-weight: 800;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    border-radius: 18px 18px 0 18px;

    @media screen and (max-width: 1023px) {
      order: 3;
      font-size: 18px;
      flex-direction: row;
      padding: 7.5px 15px;

      div {
        padding: 7.5px 0;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: row;
      align-items: flex-start;
    }

    @media screen and (max-width: 424px) {
      flex-direction: column;
	  align-items: flex-start;
    }
  }

  &.total-summary {
    border: 1px solid rgba(14, 171, 112, 0.3);
    box-shadow: 0 0 20px 0 rgba(14, 171, 112, 0.1);
  }

  &.total-summary > &--total {
    background: #0eab70;
    font-size: 22px;
    padding: 0 32px;
    min-height: 65px;

    @media screen and (max-width: 1023px) {
      font-size: 18px;
      padding: 7.5px 15px;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  &.total-summary > &-conversions {
    background: rgba(14, 171, 112, 0.1);
    box-shadow: 0 0 0 1px rgba(14, 171, 112, 0.3);
  }
}
